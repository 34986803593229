export const PrintData = [
    {
        title: "The Screenprinter",
        type: "Multi-plate Etching, Screenprint, 7\"x9\"",
        year: "2024",
        img: "theScreenPrinter"
    },
    {
        title: "Sea Serpent Divided",
        type: "Screenprint, Hand Coloring, 25\"x8\"",
        year: "2024",
        img: "seaSerpentDivided"
    },
    {
        title: "Explosion (Picking Up the Pieces)",
        type: "Screenprint, Resin, Matches, 4\"x4\"x2\"",
        year: "2023",
        img: "explosionPickingUpThePieces"
    },
    {
        title: "Tea Ceremony",
        type: "Jigsaw Linocut, 10\"x10\"",
        year: "2023",
        img: "teaCeremony"
    },
    {
        title: "Brother",
        type: "Reductive Linocut, 13\"x10\"",
        year: "2023",
        img: "brother"
    },
    {
        title: "The World Is Quiet Here",
        type: "Screenprint, 14\"x11\"",
        year: "2023",
        img: "theWorldIsQuietHere"
    },
    {
        title: "Warrior Rests",
        type: "Aquatint, 9\"x6\"",
        year: "2023",
        img: "warriorRests"
    },
    {
        title: "The Tigress Dies",
        type: "Woodcut, 9\"x11\"",
        year: "2023",
        img: "theTigressDies"
    },
    {
        title: "Contradictions",
        type: "Linocut, 5\"x3\"",
        year: "2023",
        img: "contradictions"
    },
    {
        title: "Unnatural Animals",
        type: "Woodcut, 10\"x10\"",
        year: "2022",
        img: "unnaturalAnimals"
    },
    {
        title: "Tigershark",
        type: "Photopolymer Relief, 4\"x8\"",
        year: "2022",
        img: "tigershark"
    },
    {
        title: "The Tiger and The Rabbit",
        type: "Copper Etching, 7\"x9\"",
        year: "2022",
        img: "theTigerAndTheRabbit"
    },
    {
        title: "Ishmael",
        type: "Woodcut, 12\"x16\"",
        year: "2022",
        img: "ishmael"
    },
    {
        title: "The Hanged Man",
        type: "Linocut, 17\"x11\"",
        year: "2022",
        img: "theHangedMan"
    },
];