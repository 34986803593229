const PrintImages = {
  brother: require('../imgs/prints/Brother.jpg'),
  contradictions: require('../imgs/prints/Contradictions.jpg'),
  explosionPickingUpThePieces: require('../imgs/prints/Explosion-PickingUpThePieces.jpg'),
  ishmael: require('../imgs/prints/Ishmael.jpg'),
  seaSerpentDivided: require('../imgs/prints/SeaSerpentDivided.jpg'),
  teaCeremony: require('../imgs/prints/TeaCeremony.jpg'),
  theHangedMan: require('../imgs/prints/TheHangedMan.jpg'),
  theScreenPrinter: require('../imgs/prints/TheScreenPrinter.jpg'),
  theTigerAndTheRabbit: require('../imgs/prints/TheTigerAndTheRabbit.jpg'),
  theTigressDies: require('../imgs/prints/TheTigressDies.jpg'),
  theWorldIsQuietHere: require('../imgs/prints/TheWorldIsQuietHere.jpg'),
  tigershark: require('../imgs/prints/TigerShark.jpg'),
  unnaturalAnimals: require('../imgs/prints/UnnaturalAnimals.jpg'),
  warriorRests: require('../imgs/prints/WarriorRests.jpg')
};

export default PrintImages